export class StringHelper {
  static toCapitalizedWords(name: string): string {
    if (!name) {
      return '';
    }

    const words = name.match(/[A-Za-z][a-z]*/g);

    if (!words) {
      return name;
    }

    return words.map(this.capitalize).join(' ');
  }

  private static capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }
}
