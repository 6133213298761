import Vue from 'vue';

import { StringHelper } from '@/helpers/string-helper';

export default Vue.filter(
  'enumDisplay',
  (value: number, enumType: Record<number, string>): string => {
    if (!enumType) {
      return '';
    }

    const name = enumType[value];
    return StringHelper.toCapitalizedWords(name);
  },
);
