import Vue from 'vue';

import { DateHelper } from '@/helpers/date-helper';

export default Vue.filter('date', (value: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  return DateHelper.parseDate(value);
});
