











































































import Vue from 'vue';

import AccountChecklists from '@/components/accounts/account/AccountChecklists.vue';
import AccountInformation from '@/components/accounts/account/AccountInformation.vue';
import AccountProjects from '@/components/accounts/account/AccountProjects.vue';
import AccountUsers from '@/components/accounts/account/AccountUsers.vue';
import InvitedProjects from '@/components/accounts/account/InvitedProjects.vue';
import { AdminAccountDisplay } from '@/models/accounts/account-admin-display';
import { AccountDetails } from '@/models/accounts/account-details';
import { UserStatusEnum } from '@/models/accounts/user-status-enum';
import { Category } from '@/models/categories/category';
import { ChecklistProgress } from '@/models/checklists/checklist-progress';
import { accountChecklistsService } from '@/services/account-checklists.service';
import { accountService } from '@/services/account.service';
import { categoryService } from '@/services/category.service';
import { parentCompanyService } from '@/services/parent-company.service';

export default Vue.extend({
  name: 'AccountDetails',
  components: {
    AccountInformation,
    AccountProjects,
    AccountChecklists,
    AccountUsers,
    InvitedProjects,
  },
  data() {
    return {
      accountDetails: null as AccountDetails | null,
      categories: [] as Array<Category>,
      parentCompanies: [] as Array<string>,
      accountChecklists: null as ChecklistProgress | null,
      isLoading: true,
      statusEnum: UserStatusEnum,
      active: 'users',
    };
  },
  computed: {
    accountId(): string {
      return this.$route.params.accountId;
    },
    account(): AdminAccountDisplay {
      return this.$store.getters.getAccount(this.accountId);
    },
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.isLoading = true;

      const accountDetailsPromise = accountService.getAccountDetails(
        this.accountId,
      );
      const categoriesPromise = categoryService.get();
      const parentCompaniesPromise = parentCompanyService.get();
      const checklistsPromise = accountChecklistsService.get(this.accountId);

      Promise.all([
        accountDetailsPromise,
        categoriesPromise,
        parentCompaniesPromise,
        checklistsPromise,
      ]).then(
        ([
          accountDetailsResponse,
          categoriesResponse,
          parentCompaniesResponse,
          accountChecklists,
        ]) => {
          this.accountDetails = accountDetailsResponse.data;
          this.categories = categoriesResponse.data;
          this.parentCompanies = parentCompaniesResponse.data;
          this.accountChecklists = accountChecklists.data;
          this.isLoading = false;
        },
      );
    },
  },
});
