





























import Vue from 'vue';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { StorageHelper } from '@/helpers/storage-helper';
import { AdminMetadata } from '@/models/accounts/admin-metadata';
import { AccountActionTypes } from '@/store/account-action-types';

export default Vue.extend({
  name: 'AllMetadata',
  components: {
    LoadingSpinner,
  },
  computed: {
    metadata(): AdminMetadata {
      return this.$store.state.accountsStore.metadata;
    },
    isLoading(): boolean {
      return this.$store.state.accountsStore.isMetadataLoading;
    },
    storageUsed(): string {
      return StorageHelper.toMbString(this.metadata.storage);
    },
  },
  created() {
    this.$store.dispatch(AccountActionTypes.FETCH_ACCOUNTS_METADATA);
  },
});
