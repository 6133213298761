




































import Vue from 'vue';

export default Vue.extend({
  name: 'ConfirmAction',
  props: {
    action: {
      type: String,
      default: 'Confirm',
    },
    type: {
      type: String,
      default: 'Confirm',
    },
  },
  computed: {
    buttonType(): string {
      if (this.type === 'Confirm') {
        return 'button-okay';
      }
      return 'button-error';
    },
  },
  methods: {
    close(confirm: boolean) {
      this.$emit('close', confirm);
    },
  },
});
