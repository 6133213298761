import Axios, { AxiosResponse } from 'axios';

export class ManagedAccountService {
  private readonly resourceName = '/managed-accounts';

  createManaged(email: string, accountName: string): Promise<AxiosResponse> {
    return Axios.post(this.resourceName, {
      email,
      accountName,
    });
  }

  startManagedAccountInvoice(accountId: string, numberOfSeats: number): Promise<void> {
    return Axios.put(
      `${this.resourceName}/${accountId}/pay-by-invoice`,
      {
        numberOfSeats,
      },
    );
  }
}

export const managedAccountService = new ManagedAccountService();
