
































import Vue, { PropType } from 'vue';

import ScrollableTableColumn from '@/models/shared/scrollable-table-column';

export default Vue.extend({
  name: 'ScrollableTable',
  props: {
    columns: {
      type: Array as PropType<ScrollableTableColumn[]>,
      default: () => [],
    },
    accountId: {
      type: String,
      default: undefined,
    },
    userIds: {
      type: Array as PropType<number[] | null>,
      default: () => [],
    },
  },
  methods: {
    toggleValue(column: ScrollableTableColumn, id: string) {
      const cellToUpdate = this.columns
        .find((c) => c === column)
        ?.data.find((d) => d.id === id);
      if (cellToUpdate) {
        cellToUpdate.value = !cellToUpdate.value;
      }
    },
  },
});
