import Axios, { AxiosResponse } from 'axios';

export class ParentCompanyService {
  private readonly resourceName = '/parent-companies';

  async get(): Promise<AxiosResponse<string[]>> {
    return Axios.get<string[]>(this.resourceName);
  }
}

export const parentCompanyService = new ParentCompanyService();
