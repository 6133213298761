import Axios from 'axios';
import Vue from 'vue';
import vSelect from 'vue-select';

import App from './App.vue';
import { AxiosConfiguration } from './configuration/axios-configuration';
import { VueToasterConfiguration } from './configuration/vue-toaster-configuration';
import iconDirective from './directives/icon-directive';
import router from './router';
import store from './store/store';

import '@/scss/global.scss';
import '@/filters/enum.filter';
import '@/filters/date.filter';
import '@/filters/datetime.filter';

Vue.component('VSelect', vSelect);

Vue.config.productionTip = false;

AxiosConfiguration.configureAxios(Axios);
VueToasterConfiguration.configureVueToaster(Vue);

Vue.directive('icon', iconDirective);

new Vue({
  router,
  store,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render: (h) => h(App),
}).$mount('#app');
