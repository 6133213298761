import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options.d';

import { IconHelper } from '@/helpers/icon-helper';

// Custom directive for using a feather icon
export default Vue.directive('icon', {
  inserted(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.arg) {
      el.innerHTML = IconHelper.icon(binding.arg);
    } else if (binding.value) {
      el.innerHTML = IconHelper.icon(binding.value);
    }
  },
  unbind(el: HTMLElement) {
    el.innerHTML = '';
  },
});
