import Axios, { AxiosResponse } from 'axios';

import { ChecklistProgress } from '@/models/checklists/checklist-progress';

export class AccountChecklistsService {
  // eslint-disable-next-line class-methods-use-this
  private readonly resourceName = (id: string): string => `/accounts/${id}/checklists`;

  get(accountId: string): Promise<AxiosResponse<ChecklistProgress>> {
    return Axios.get(this.resourceName(accountId));
  }
}

export const accountChecklistsService = new AccountChecklistsService();
