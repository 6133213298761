export class AccountMutationTypes {
  static FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';

  static FETCH_ACCOUNTS_METADATA = 'FETCH_ACCOUNTS_METADATA';

  static TOGGLE_ACCOUNT = 'TOGGLE_ACCOUNT';

  static TOGGLE_ACCOUNT_REVIEWED = 'TOGGLE_ACCOUNT_REVIEWED';

  static SET_CATEGORY = 'SET_CATEGORY';

  static SET_PARENT_COMPANY = 'SET_PARENT_COMPANY';

  static SET_TRIAL_EXPIRY = 'SET_TRIAL_EXPIRY';

  static ACCOUNTS_LOADING = 'ACCOUNTS_LOADING';

  static ACCOUNTS_METADATA_LOADING = 'ACCOUNTS_METADATA_LOADING';

  static ADD_ACCOUNT = 'ADD_ACCOUNT';

  static SET_TO_PAY_BY_INVOICE = ' SET_TO_PAY_BY_INVOICE';

  static START_MANAGED_ACCOUNT_INVOICE = 'START_MANAGED_ACCOUNT_INVOICE';

  static SET_CUSTOM_HOST = 'SET_CUSTOM_HOST';

  static SET_READ_ONLY_USER_LIMIT = 'SET_READ_ONLY_USER_LIMIT';
}
