import { AxiosError, AxiosStatic, AxiosResponse } from 'axios';
import Vue from 'vue';

import { authService } from '@/services/auth.service';
import { EnvironmentConfig } from '@/utilities/environment-config';

export class AxiosConfiguration {
  static configureAxios(axios: AxiosStatic): void {
    axios.defaults.baseURL = EnvironmentConfig.config.rootApi;
    AxiosConfiguration.configureInterceptors(axios);
  }

  static configureInterceptors(axios: AxiosStatic): void {
    axios.interceptors.request.use(async (config) => {
      const accessToken = await authService.getAccessToken();
      if (accessToken) {
        if (!config.headers) {
          config.headers = {};
        }

        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        AxiosConfiguration.errorResponseHandler(error);
        return Promise.reject(error);
      },
    );
  }

  static errorResponseHandler(error: AxiosError): void {
    let errorMessage = 'A server error occurred';
    if (error.response) {
      const { url } = error.config;
      switch (error.response.status) {
        case 400:
          errorMessage = AxiosConfiguration.handleServerError(error.response);
          break;
        case 401:
          errorMessage = `You are not authorized to access ${url}`;
          break;
        case 403:
          errorMessage = `You do not have access to ${url}`;
          break;
        case 404:
          errorMessage = `Could not find the resource at ${url}`;
          break;
        case 408:
          errorMessage = `Request to ${url} timed out, please try again`;
          break;
        case 500:
          errorMessage = AxiosConfiguration.handleServerError(error.response);
          break;
        default:
          // When custom errors are sent from the server, handle them here
          break;
      }
    }
    Vue.toasted.global.error(errorMessage);
  }

  static handleServerError(errorResponse: AxiosResponse): string {
    let body = errorResponse.data;

    try {
      body = JSON.parse(body);
    } catch {
      // need empty catch
    }

    if (body && body.messages) {
      return `The following error(s) occurred: ${body.messages
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((message: any) => message.message || message.Message || message)
        .join('. ')}.`;
    }
    return 'An unknown server error occurred';
  }
}
