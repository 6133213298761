import {
  UserManager,
  WebStorageStateStore,
  User,
  UserManagerSettings,
} from 'oidc-client';

import { EnvironmentConfig } from '@/utilities/environment-config';

export class AuthService {
  private userManager: UserManager;

  constructor() {
    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: EnvironmentConfig.config.rootIdentity,
      client_id: 'Admin',
      redirect_uri: `${EnvironmentConfig.config.rootUi}/callback.html`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${EnvironmentConfig.config.rootUi}/silent-renew.html`,
      response_type: 'code',
      scope: 'openid profile Admin roles',
      post_logout_redirect_uri: `${EnvironmentConfig.config.rootUi}/`,
      filterProtocolClaims: true,
    };

    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public async login(): Promise<void> {
    await this.userManager.signinRedirect();
  }

  public async logout(): Promise<void> {
    await this.userManager.signoutRedirect();
  }

  public async signInSilent(): Promise<void> {
    await this.userManager.signinSilent();
  }

  public async getAccessToken(): Promise<string | null> {
    const user = await this.userManager.getUser();
    let accessToken = null;
    if (user) {
      accessToken = user.access_token;
    }

    return accessToken;
  }
}

export const authService = new AuthService();
