/* eslint-disable no-underscore-dangle */
import { AxiosResponse } from 'axios';
import { Module } from 'vuex';

import { AdminAccountDisplay } from '@/models/accounts/account-admin-display';
import { AccountStatus } from '@/models/accounts/account-status';
import { AdminMetadata } from '@/models/accounts/admin-metadata';
import { PaymentMethod } from '@/models/accounts/payment-method';
import { AdminAccountDisplaySearchRequest } from '@/models/search/accounts/admin-account-display-search-request';
import { IPage } from '@/models/search/i-page';
import { accountService } from '@/services/account.service';
import { managedAccountService } from '@/services/managed-account.service';
import { AccountActionTypes } from '@/store/account-action-types';
import { AccountMutationTypes } from '@/store/account-mutation-types';
import type { VueMutationTree } from '@/store/store';

type AccountStoreState = {
  accounts: IPage<AdminAccountDisplay> | null;
  metadata: AdminMetadata | null;
  isAccountsLoading: boolean;
  isMetadataLoading: boolean;
};

const accountsStore: Module<AccountStoreState, unknown> = {
  state: {
    accounts: null,
    metadata: null,
    isAccountsLoading: true,
    isMetadataLoading: true,
  },
  mutations: {
    [AccountMutationTypes.FETCH_ACCOUNTS](
      state,
      accounts: IPage<AdminAccountDisplay>,
    ) {
      state.accounts = accounts;
      state.isAccountsLoading = false;
    },
    [AccountMutationTypes.ADD_ACCOUNT](state, account: AdminAccountDisplay) {
      if (state.accounts) {
        state.accounts.data.push(account);
      } else {
        state.accounts = {
          data: [account],
        } as IPage<AdminAccountDisplay>;
      }
    },
    [AccountMutationTypes.FETCH_ACCOUNTS_METADATA](
      state,
      metadata: AdminMetadata,
    ) {
      state.metadata = metadata;
    },
    [AccountMutationTypes.TOGGLE_ACCOUNT](state, accountId: string) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.accountEnabled = !account.accountEnabled;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        const message = account.accountEnabled ? 'enabled' : 'disabled';
        toasted.global.success(`Successfully set account to ${message}`);
      }
    },
    [AccountMutationTypes.TOGGLE_ACCOUNT_REVIEWED](state, accountId: string) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.reviewed = !account.reviewed;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        const message = account.reviewed ? 'reviewed' : 'not reviewed';
        toasted.global.success(`Successfully set account as ${message}`);
      }
    },
    [AccountMutationTypes.SET_PARENT_COMPANY](
      state,
      data: { accountId: string; parentCompany: string },
    ) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === data.accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.parentCompany = data.parentCompany;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        toasted.global.success('Successfully changed parent company');
      }
    },
    [AccountMutationTypes.SET_CATEGORY](
      state,
      data: { accountId: string; categoryId: string },
    ) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === data.accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.categoryId = data.categoryId;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        toasted.global.success('Successfully changed account category');
      }
    },
    [AccountMutationTypes.SET_READ_ONLY_USER_LIMIT](
      state,
      data: { accountId: string; readOnlyUserLimit: number },
    ) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === data.accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.readOnlyUserLimit = data.readOnlyUserLimit;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        toasted.global.success('Successfully changed read-only user limit');
      }
    },
    [AccountMutationTypes.SET_TRIAL_EXPIRY](
      state,
      data: { accountId: string; trialExpiry: Date },
    ) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === data.accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.trialExpiry = data.trialExpiry;
        account.accountStatus = AccountStatus.Trial;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        toasted.global.success('Successfully changed Trial End date');
      }
    },
    [AccountMutationTypes.SET_TO_PAY_BY_INVOICE](
      state,
      data: { accountId: string },
    ) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === data.accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.paymentMethod = PaymentMethod.Invoice;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        toasted.global.success('Successfully changed to pay by Invoice');
      }
    },
    [AccountMutationTypes.START_MANAGED_ACCOUNT_INVOICE](
      state,
      data: { accountId: string; seatNumber: number },
    ) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === data.accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.paymentMethod = PaymentMethod.Invoice;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        toasted.global.success(
          `Successfully started invoicing for ${data.seatNumber} seats`,
        );
      }
    },
    [AccountMutationTypes.SET_CUSTOM_HOST](
      state,
      data: { accountId: string; customHost: string },
    ) {
      const account = state.accounts?.data.find(
        (a: AdminAccountDisplay) => a.accountId === data.accountId,
      ) as AdminAccountDisplay;

      if (account) {
        account.customHost = data.customHost;
        const toasted = (this as VueMutationTree)._vm.$toasted;
        const action = data.customHost ? 'set' : 'removed';

        toasted.global.success(`Successfully ${action} Custom Host`);
      }
    },
    [AccountMutationTypes.ACCOUNTS_LOADING](state, loading: boolean) {
      state.isAccountsLoading = loading;
    },
    [AccountMutationTypes.ACCOUNTS_METADATA_LOADING](state, loading: boolean) {
      state.isMetadataLoading = loading;
    },
  },
  actions: {
    [AccountActionTypes.FETCH_ACCOUNTS](
      context,
      request: AdminAccountDisplaySearchRequest,
    ): Promise<AxiosResponse<IPage<AdminAccountDisplay>>> {
      return new Promise((resolve, reject) => {
        context.commit(AccountMutationTypes.ACCOUNTS_LOADING, true);
        accountService.search(request).then(
          (response) => {
            context.commit(AccountMutationTypes.FETCH_ACCOUNTS, response.data);
            resolve(response);
          },
          (error) => {
            context.commit(AccountMutationTypes.ACCOUNTS_LOADING, false);
            reject(error);
          },
        );
      });
    },
    [AccountActionTypes.TOGGLE_ACCOUNT](
      context,
      accountId: string,
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        accountService.toggleAccount(accountId).then(
          (response) => {
            context.commit(AccountMutationTypes.TOGGLE_ACCOUNT, accountId);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    [AccountActionTypes.TOGGLE_ACCOUNT_REVIEWED](
      context,
      accountId: string,
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        accountService.toggleReviewed(accountId).then(
          (response) => {
            context.commit(
              AccountMutationTypes.TOGGLE_ACCOUNT_REVIEWED,
              accountId,
            );
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    [AccountActionTypes.SET_PARENT_COMPANY](
      context,
      data: { accountId: string; parentCompany: string },
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        accountService
          .setParentCompany(data.accountId, data.parentCompany)
          .then(
            (response) => {
              context.commit(AccountMutationTypes.SET_PARENT_COMPANY, data);
              resolve(response);
            },
            (error) => {
              reject(error);
            },
          );
      });
    },
    [AccountActionTypes.SET_TRIAL_EXPIRY](
      context,
      data: { accountId: string; trialExpiry: Date },
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        accountService.setTrialExpiry(data.accountId, data.trialExpiry).then(
          (response) => {
            context.commit(AccountMutationTypes.SET_TRIAL_EXPIRY, data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    [AccountActionTypes.SET_TO_PAY_BY_INVOICE](
      context,
      data: { accountId: string },
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        accountService.setToPayByInvoice(data.accountId).then(
          (response) => {
            context.commit(AccountMutationTypes.SET_TO_PAY_BY_INVOICE, data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    [AccountActionTypes.START_MANAGED_ACCOUNT_INVOICE](
      context,
      data: { accountId: string; seatNumber: number },
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        managedAccountService
          .startManagedAccountInvoice(data.accountId, data.seatNumber)
          .then(
            () => {
              context.commit(
                AccountMutationTypes.START_MANAGED_ACCOUNT_INVOICE,
                data,
              );
              resolve();
            },
            (error) => {
              reject(error);
            },
          );
      });
    },
    [AccountActionTypes.SET_CUSTOM_HOST](
      context,
      data: { accountId: string; customHost: string },
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        accountService.setCustomHost(data.accountId, data.customHost).then(
          (response) => {
            context.commit(AccountMutationTypes.SET_CUSTOM_HOST, data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    [AccountActionTypes.SET_CATEGORY](
      context,
      data: { accountId: string; categoryId: string },
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        accountService.setCategory(data.accountId, data.categoryId).then(
          (response) => {
            context.commit(AccountMutationTypes.SET_CATEGORY, data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    [AccountActionTypes.SET_READ_ONLY_USER_LIMIT](
      context,
      data: { accountId: string; readOnlyUserLimit: number },
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        accountService.setReadOnlyUserLimit(data.accountId, data.readOnlyUserLimit).then(
          (response) => {
            context.commit(AccountMutationTypes.SET_READ_ONLY_USER_LIMIT, data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
    [AccountActionTypes.FETCH_ACCOUNTS_METADATA](
      context,
    ): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        context.commit(AccountMutationTypes.ACCOUNTS_METADATA_LOADING, true);
        accountService
          .getAllMetadata()
          .then(
            (response) => {
              context.commit(
                AccountMutationTypes.FETCH_ACCOUNTS_METADATA,
                response.data,
              );
              resolve(response);
            },
            (error) => {
              reject(error);
            },
          )
          .finally(() => context.commit(
            AccountMutationTypes.ACCOUNTS_METADATA_LOADING,
            false,
          ));
      });
    },
  },
  getters: {
    getAccount: (state) => (id: string) => state.accounts?.data.find(
      (account: AdminAccountDisplay) => account.accountId === id,
    ),
  },
};
export default accountsStore;
