export enum FieldType {
  Text = 0,
  Number = 1,
  Money = 2,
  Date = 3,
  Time = 4,
  DateTime = 5,
  Boolean = 6,
  File = 7,
  List = 8,
  Child = 9,
  Parent = 10,
  Counter = 11,
  Image = 12,
  Icon = 13,
  Computed = 14,
  User = 15,
  Percentage = 16,
  ManyToMany = 17,
  MultiSelectList = 18,
}
