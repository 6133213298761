import Vue, { VueConstructor } from 'vue';
import Toasted from 'vue-toasted';

import { IconHelper } from '@/helpers/icon-helper';

export class VueToasterConfiguration {
  static configureVueToaster(vue: VueConstructor<Vue>): void {
    vue.use(Toasted, {
      theme: 'bubble',
      iconPack: 'callback',
      position: 'bottom-center',
      duration: 3000,
    });
    vue.toasted.register(
      'success',
      (data) => (data || 'Action completed successfully'),
      {
        type: 'success',
        icon: (el) => {
          el.innerHTML = IconHelper.icon('check');
          return el;
        },
      },
    );
    vue.toasted.register('error', (data) => (data || 'Had an error'), {
      type: 'error',
      duration: 10000,
      icon: (el) => {
        el.innerHTML = IconHelper.icon('x');
        return el;
      },
    });
  }
}
