











































































































































































































































































































import { mixins } from 'vue-class-component';
import { RawLocation } from 'vue-router';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import HeaderCell from '@/components/shared/table/HeaderCell.vue';
import PagingControls from '@/components/shared/table/PagingControls.vue';
import SearchTable from '@/mixins/search-table';
import { AdminAccountDisplay } from '@/models/accounts/account-admin-display';
import { AccountStatus } from '@/models/accounts/account-status';
import { UserStatusEnum } from '@/models/accounts/user-status-enum';
import { Category } from '@/models/categories/category';
import { ChecklistModuleType } from '@/models/checklists/checklist-module-types';
import { RouteNames } from '@/models/config/route-name';
import { IPage } from '@/models/search/i-page';
import { AccountActionTypes } from '@/store/account-action-types';

export default mixins(SearchTable).extend({
  name: 'AccountsTable',
  components: {
    HeaderCell,
    LoadingSpinner,
    PagingControls,
  },
  props: {
    categories: {
      type: Array as () => Array<Category>,
      required: true,
    },
    parentCompanies: {
      type: Array as () => Array<string>,
      required: true,
    },
  },
  data() {
    return {
      statusEnum: UserStatusEnum,
      accountStatus: AccountStatus,
      totalChecklists: Object.keys(ChecklistModuleType).length / 2,
    };
  },
  methods: {
    toggleAccount(accountId: string) {
      this.$store.dispatch(AccountActionTypes.TOGGLE_ACCOUNT, accountId);
    },
    toggleReviewed(accountId: string) {
      this.$store.dispatch(
        AccountActionTypes.TOGGLE_ACCOUNT_REVIEWED,
        accountId,
      );
    },
    setParent(accountId: string, parentCompany: string) {
      // Add to list of options (if not present) so it can be filtered by
      if (this.parentCompanies.indexOf(parentCompany) < 0 && parentCompany) {
        this.parentCompanies.push(parentCompany);

        // Sort so filter is alphabetical
        this.parentCompanies.sort();
      }

      this.$store.dispatch(AccountActionTypes.SET_PARENT_COMPANY, {
        accountId,
        parentCompany,
      });
    },
    setCategory(accountId: string, categoryId: string) {
      this.$store.dispatch(AccountActionTypes.SET_CATEGORY, {
        accountId,
        categoryId,
      });
    },
    getAccountRoute(accountId: string): RawLocation {
      return {
        name: RouteNames.ACCOUNT_DETAILS,
        params: {
          accountId,
        },
      } as RawLocation;
    },
    viewAccountInNewWindow(accountId: string | null) {
      if (accountId) {
        const route = this.$router.resolve(this.getAccountRoute(accountId));
        window.open(route.href, '_blank');
      }
    },
  },
  computed: {
    accounts(): IPage<AdminAccountDisplay> {
      return this.$store.state.accountsStore.accounts;
    },
    isLoading(): boolean {
      return this.$store.state.accountsStore.isAccountsLoading;
    },
  },
});
