









































import Vue, { PropType } from 'vue';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { AccountDetails } from '@/models/accounts/account-details';
import { UserStatusEnum } from '@/models/accounts/user-status-enum';

export default Vue.extend({
  name: 'AccountUsers',
  components: {
    LoadingSpinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    accountDetails: {
      type: Object as PropType<AccountDetails>,
      default: null,
    },
  },
  data() {
    return {
      statusEnum: UserStatusEnum,
    };
  },
});
