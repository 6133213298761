













































































































































































































































































































































import Vue, { PropType } from 'vue';

import ConfirmAction from '@/components/shared/ConfirmAction.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { DateHelper } from '@/helpers/date-helper';
import { StorageHelper } from '@/helpers/storage-helper';
import { AdminAccountDisplay } from '@/models/accounts/account-admin-display';
import { AccountStatus } from '@/models/accounts/account-status';
import { PaymentMethod } from '@/models/accounts/payment-method';
import { UserStatusEnum } from '@/models/accounts/user-status-enum';
import { Category } from '@/models/categories/category';
import { ChecklistModuleType } from '@/models/checklists/checklist-module-types';
import { AdminAccountDisplaySearchRequest } from '@/models/search/accounts/admin-account-display-search-request';
import { accountService } from '@/services/account.service';
import { AccountActionTypes } from '@/store/account-action-types';
import { AccountMutationTypes } from '@/store/account-mutation-types';
import { EnvironmentConfig } from '@/utilities/environment-config';

export default Vue.extend({
  name: 'AccountInformation',
  components: {
    LoadingSpinner,
    ConfirmAction,
  },
  props: {
    account: {
      type: Object as PropType<AdminAccountDisplay>,
      default: null,
    },
    categories: {
      type: Array as () => Array<Category>,
      required: true,
    },
    parentCompanies: {
      type: Array as () => Array<string>,
      required: true,
    },
  },
  data() {
    return {
      localAccount: {} as AdminAccountDisplay,
      statusEnum: UserStatusEnum,
      accountStatus: AccountStatus,
      paymentMethod: PaymentMethod,
      isLoading: true,
      showPaymentMethodConfirm: false,
      totalChecklists: Object.keys(ChecklistModuleType).length / 2,
      subdomainPlaceholder: EnvironmentConfig.config.customSubdomain,
      seatNumber: 0,
      showSeatValidation: false,
    };
  },
  computed: {
    canEditTrialExpiry(): boolean {
      return (
        this.localAccount.accountStatus === AccountStatus.Trial
        || this.localAccount.accountStatus === AccountStatus.NoSubscription
      );
    },
    canChangeToInvoice(): boolean {
      const status = this.localAccount?.accountStatus;

      return (
        (status === AccountStatus.Trial
          || status === AccountStatus.NoSubscription)
        && this.localAccount.paymentMethod === PaymentMethod.Card
      );
    },
    canSaveCustomHost(): boolean {
      return (
        !!this.localAccount.customHost
        && this.localAccount.customHost !== this.account.customHost
      );
    },
    canClearCustomHost(): boolean {
      return !!this.account.customHost;
    },
    localTrialExpiry: {
      get(): string | undefined {
        let parsedDate: string | undefined = '';
        if (this.account) {
          parsedDate = DateHelper.parseDateForDatepicker(
            this.account.trialExpiry?.toString(),
          );
        }
        return parsedDate;
      },
      set(newValue: string): void {
        if (!Number.isNaN(Date.parse(newValue))) {
          this.setTrialExpiry(newValue);
        }
      },
    },
    invoiceMessage(): string {
      if (this.managedAccount) {
        return 'Choose a number of seats and click confirm to start invoicing this managed account:';
      }
      return 'Are you sure you want to change the account to pay by invoice?';
    },
    managedAccount(): boolean {
      return this.localAccount.accountStatus === this.accountStatus.Managed;
    },
  },
  created() {
    if (this.account !== null) {
      this.setLocalAccount(this.account);
      this.isLoading = false;
      return;
    }

    const accountId = this.$route.params.accountId as string;
    this.getAccount(accountId);
  },
  methods: {
    storageString(value: number): string {
      return StorageHelper.toMbString(value);
    },
    getAccount(accountId: string) {
      const request = {
        accountId,
      } as AdminAccountDisplaySearchRequest;

      accountService.search(request).then((response) => {
        const account = response.data.data[0];
        this.setLocalAccount(account);
        this.$store.commit(AccountMutationTypes.ADD_ACCOUNT, account);
        this.isLoading = false;
      });
    },
    setLocalAccount(account: AdminAccountDisplay): void {
      this.localAccount = JSON.parse(JSON.stringify(account));
    },
    setParent(parentCompany: string) {
      this.$store.dispatch(AccountActionTypes.SET_PARENT_COMPANY, {
        accountId: this.localAccount.accountId,
        parentCompany,
      });
    },
    setCategory(categoryId: string) {
      this.$store.dispatch(AccountActionTypes.SET_CATEGORY, {
        accountId: this.localAccount.accountId,
        categoryId,
      });
    },
    setReadOnlyUserLimit() {
      const limit = this.localAccount.readOnlyUserLimit;

      if (typeof limit !== 'number' || limit < 0) {
        Vue.toasted.error(
          'Read-only user limit must be a positive integer.',
        );
        return;
      }

      this.$store.dispatch(AccountActionTypes.SET_READ_ONLY_USER_LIMIT, {
        accountId: this.localAccount.accountId,
        readOnlyUserLimit: limit,
      });
    },
    setTrialExpiry(trialExpiry: string): void {
      const trialExpiryDate = new Date(trialExpiry);
      if (trialExpiryDate === this.account.trialExpiry) {
        return;
      }
      if (!this.canEditTrialExpiry) {
        Vue.toasted.error(
          "Trial Expiry can only be edited for a user with status 'Trial' or 'NoSubscription'.",
        );
        return;
      }
      const lastValidDate = new Date();
      lastValidDate.setMonth(lastValidDate.getMonth() + 3);
      if (trialExpiryDate > lastValidDate) {
        Vue.toasted.error(
          'Trial Expiry cannot be set to more than 3 months in the future.',
        );
        return;
      }
      this.$store.dispatch(AccountActionTypes.SET_TRIAL_EXPIRY, {
        accountId: this.localAccount.accountId,
        trialExpiry: trialExpiryDate,
      });
    },
    tryAppendSubdomain(event: InputEvent): void {
      // On input, if we have a single character, append the subdomain
      if (
        event.inputType === 'insertText'
        && this.localAccount.customHost?.length === 1
      ) {
        this.localAccount.customHost += this.subdomainPlaceholder;

        this.$nextTick(() => {
          const input = this.$refs.subdomainInput as HTMLInputElement;
          input.setSelectionRange(1, 1);
        });
      }
    },
    setCustomHost(customHost: string): void {
      if (this.canSaveCustomHost) {
        this.$store.dispatch(AccountActionTypes.SET_CUSTOM_HOST, {
          accountId: this.localAccount.accountId,
          customHost,
        });
      }
    },
    clearCustomHost(): void {
      if (this.canClearCustomHost) {
        this.localAccount.customHost = null;
        this.$store.dispatch(AccountActionTypes.SET_CUSTOM_HOST, {
          accountId: this.localAccount.accountId,
          customHost: null,
        });
      }
    },
    toggleReviewed() {
      this.$store.dispatch(
        AccountActionTypes.TOGGLE_ACCOUNT_REVIEWED,
        this.localAccount.accountId,
      );
    },
    closePaymentMethodConfirmModal(changePaymentMethod: boolean) {
      this.showPaymentMethodConfirm = false;

      if (!changePaymentMethod) {
        return;
      }

      if (
        this.localAccount.accountStatus !== this.accountStatus.Managed
        && !this.canChangeToInvoice
      ) {
        Vue.toasted.error('Cannot change account to pay by invoice');
        return;
      }

      if (this.localAccount.accountStatus === this.accountStatus.Managed) {
        this.$store.dispatch(AccountActionTypes.START_MANAGED_ACCOUNT_INVOICE, {
          accountId: this.localAccount.accountId,
          seatNumber: Number(this.seatNumber),
        });
      } else {
        this.$store.dispatch(AccountActionTypes.SET_TO_PAY_BY_INVOICE, {
          accountId: this.localAccount.accountId,
        });
      }
    },
  },
});
