import { Component } from 'vue';

export default class ScrollableTableColumn {
  name: string;

  data: { id: string; value: unknown }[];

  component?: Component;

  constructor(
    name: string,
    component?: Component,
    data?: { id: string; value: unknown }[],
  ) {
    this.name = name;
    this.data = data || [];
    this.component = component;
  }
}
