export class DateHelper {
  static parseDate(input: string): string | undefined {
    if (input === null) {
      return undefined;
    }

    const date = new Date(input);

    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }).format(date);
  }

  static parseDatetime(input: string): string | undefined {
    if (input === null) {
      return undefined;
    }

    const date = new Date(input);

    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }

  static parseDateForDatepicker(input: string | undefined): string | undefined {
    if (input === null || input === undefined) {
      return undefined;
    }

    const date = new Date(input);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}`;
  }
}
