export class AccountActionTypes {
  static readonly FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';

  static readonly FETCH_ACCOUNTS_METADATA = 'FETCH_ACCOUNTS_METADATA';

  static readonly TOGGLE_ACCOUNT = 'TOGGLE_ACCOUNT';

  static readonly TOGGLE_ACCOUNT_REVIEWED = 'TOGGLE_ACCOUNT_REVIEWED';

  static readonly SET_CATEGORY = 'SET_CATEGORY';

  static readonly SET_PARENT_COMPANY = 'SET_PARENT_COMPANY';

  static readonly SET_TRIAL_EXPIRY = 'SET_TRIAL_EXPIRY';

  static SET_TO_PAY_BY_INVOICE = ' SET_TO_PAY_BY_INVOICE';

  static START_MANAGED_ACCOUNT_INVOICE = 'START_MANAGED_ACCOUNT_INVOICE';

  static SET_CUSTOM_HOST = 'SET_CUSTOM_HOST';

  static SET_READ_ONLY_USER_LIMIT = 'SET_READ_ONLY_USER_LIMIT';
}
