import Axios, { AxiosResponse } from 'axios';

import { Querystring } from '@/helpers/http/query-string';
import { EmailSearchResult } from '@/models/emails/email-search-result';
import { ProjectAndEmailSearchRequest } from '@/models/search/accounts/project-and-email-search-request';
import { IPage } from '@/models/search/i-page';

export class EmailService {
  private readonly resourceName = '/emails';

  search(
    request: ProjectAndEmailSearchRequest,
  ): Promise<AxiosResponse<IPage<EmailSearchResult>>> {
    const url = `${this.resourceName}/search`;

    const queryString = new Querystring(request);

    const fullUrl = `${url}${queryString}`;
    return Axios.get<IPage<EmailSearchResult>>(fullUrl);
  }

  resend(emailId: string): Promise<AxiosResponse> {
    return Axios.post(`${this.resourceName}/${emailId}`);
  }
}

export const emailService = new EmailService();
