






























































































































































import moment from 'moment';
import Vue from 'vue';

import CreateManagedAccountModal from '@/components/shared/CreateManagedAccountModal.vue';
import { EnumHelper } from '@/helpers/enum-helper';
import { AccountStatus } from '@/models/accounts/account-status';
import { UserStatusEnum } from '@/models/accounts/user-status-enum';
import { Category } from '@/models/categories/category';
import { AdminAccountDisplaySearchFilters } from '@/models/search/accounts/admin-account-display-search-request';

export default Vue.extend({
  name: 'AccountsFilter',
  components: {
    CreateManagedAccountModal,
  },
  props: {
    categories: {
      type: Array as () => Array<Category>,
      required: true,
    },
    parentCompanies: {
      type: Array as () => Array<string>,
      required: true,
    },
    triggerInitSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFilter: true,
      filters: {
        searchValue: null,
        statuses: [],
        dateJoinedFrom: null,
        lastAccessFrom: null,
        parentCompany: null,
        categoryIds: [],
        accountStatuses: [],
        hasProjects: null,
        hasUsers: null,
        userId: null,
      } as AdminAccountDisplaySearchFilters,
      managedAccountModalVisible: false,
    };
  },
  computed: {
    categoriesWithNone() {
      // Map to clone data so we don't alter underlying categories array
      const data = this.categories.map((item) => item);

      // Add an "Unassigned" option so we can explicity include those which are not yet assigned
      data.unshift({
        id: '',
        name: 'Unassigned',
        ordinal: -1,
        hideByDefault: false,
      } as Category);

      return data;
    },
    dateOptions() {
      // Get just date (remove time)
      const today = new Date(new Date().toDateString());

      const formatDate = (date: Date): string => moment(date).format('LL');

      const addDays = (date: Date, days: number): Date => {
        const newDate = new Date(date.valueOf());
        newDate.setDate(newDate.getDate() + days);
        return newDate;
      };

      const options = [
        {
          date: formatDate(addDays(today, -7)),
          name: 'Last 7 Days',
        },
        {
          date: formatDate(addDays(today, -14)),
          name: 'Last 14 Days',
        },
        {
          date: formatDate(addDays(today, -30)),
          name: 'Last 30 Days',
        },
        {
          date: formatDate(addDays(today, -90)),
          name: 'Last 90 Days',
        },
      ];

      return options;
    },
    accountStatusOptions() {
      return EnumHelper.getEnumAsIdNamePairs(AccountStatus);
    },
    statusOptions() {
      return EnumHelper.getEnumAsIdNamePairs(UserStatusEnum);
    },
    boolOptions() {
      return [
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
      ];
    },
  },
  watch: {
    triggerInitSearch(triggered: boolean) {
      if (triggered) {
        // Wait until categories has been populated (ajax finished)
        // Then populate defaults and trigger page-load search
        this.filters.dateJoinedFrom = this.dateOptions[1].date;

        this.filters.categoryIds = this.categoriesWithNone
          .filter((category) => !category.hideByDefault)
          .map((category) => category.id);

        // Trigger a search in parent
        this.changed();
      }
    },
  },
  methods: {
    changed() {
      this.$emit('change', this.filters);
    },
    exportResults() {
      this.$emit('export');
    },
    openManagedAccountModal() {
      this.managedAccountModalVisible = true;
    },
  },
});
