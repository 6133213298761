








import Vue from 'vue';

export default Vue.extend({
  name: 'CheckBoxForSupportUser',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    accountId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isUserAlreadyInProject: false,
      checkboxValue: false,
    };
  },
  mounted() {
    this.isUserAlreadyInProject = this.value;
    this.checkboxValue = this.value;
  },
  methods: {
    changeValueOnTick() {
      this.checkboxValue = !this.checkboxValue;
      this.$emit('change');
    },
  },
});
