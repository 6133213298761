









































import Vue, { PropType } from 'vue';

import { accountService } from '@/services/account.service';

export default Vue.extend({
  name: 'CreateManagedAccountModal',
  props: {
    accountId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      projectName: null as string | null,

      projectNameError: false,
      disableCreateButton: false,
    };
  },
  methods: {
    async createProject() {
      if (this.disableCreateButton) {
        return;
      }
      if (this.projectName) {
        this.disableCreateButton = true;
        this.projectNameError = false;
        await accountService.createProject(
          this.accountId,
          this.projectName,
        );

        Vue.toasted.global.success(
          `Successfully created a new project: ${this.projectName}`,
        );
        this.disableCreateButton = false;
        this.$emit('close');
      }
      this.disableCreateButton = false;
      this.projectNameError = this.projectName === null;
    },
  },
});
