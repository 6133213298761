

















import Vue, { PropType } from 'vue';

import ProjectModelExplorer from '@/components/projects/project/ProjectModelExplorer.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { ProjectModel } from '@/models/projects/model/project-model';

export default Vue.extend({
  name: 'ProjectModel',
  components: {
    LoadingSpinner,
    ProjectModelExplorer,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    projectModel: {
      type: Object as PropType<ProjectModel>,
      default: null,
    },
  },
});
