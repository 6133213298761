





































import Vue, { PropType } from 'vue';

import CheckBoxForSupportUserVue from '@/components/shared/CheckBoxForSupportUser.vue';
import ScrollableTable from '@/components/shared/ScrollableTable.vue';
import { AccountProject } from '@/models/accounts/account-project';
import { UserDetail } from '@/models/accounts/user-detail';
import { SupportUserColumnNames } from '@/models/projects/support-user-column-names';
import ScrollableTableColumn from '@/models/shared/scrollable-table-column';
import { accountService } from '@/services/account.service';
import { projectService } from '@/services/project.service';

export default Vue.extend({
  name: 'AssignSupportAccessModal',
  components: {
    ScrollableTable,
  },
  props: {
    project: {
      type: Object as PropType<AccountProject>,
      default: () => ({} as AccountProject),
    },
    accountId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      columns: [
        new ScrollableTableColumn(SupportUserColumnNames.Email),
        new ScrollableTableColumn(
          SupportUserColumnNames.AssignToProject,
          CheckBoxForSupportUserVue,
        ),
      ] as ScrollableTableColumn[],
      userIds: null as number[] | null,
    };
  },
  async created() {
    await this.getUserIds();
    await this.getSupportUsers();
  },
  methods: {
    async getSupportUsers() {
      const supportUsers = await accountService.getSupportUsers();
      this.columns.forEach((column: ScrollableTableColumn) => {
        switch (column.name) {
          case SupportUserColumnNames.Email:
            column.data = supportUsers.data.map((u: UserDetail) => ({
              id: u.userId.toString(),
              value: u.email,
            }));
            break;
          case SupportUserColumnNames.AssignToProject:
            column.data = supportUsers.data.map((u: UserDetail) => ({
              id: u.userId.toString(),
              value: this.isUserInProject(u.userId),
            }));
            break;
          default:
            throw new Error(`Unexpected Column Name: ${column.name}`);
        }
      });
    },
    async getUserIds() {
      if (this.project) {
        const userIds = await projectService.getProjectUserIds(
          this.project.projectId as string,
        );
        this.userIds = userIds.data;
      }
    },
    async save() {
      // get all user ids that have been ticked and that were not already in the project
      const supportUserIds = this.columns
        .find((c) => c.name === SupportUserColumnNames.AssignToProject)
        ?.data.filter(
          (d) => d.value === true
            && !this.userIds?.some((id) => id.toString() === d.id),
        )
        .map((d) => d.id);
      // now need to add the user ids to this project as project admins
      await projectService
        .addSupportUser(
          this.project.projectId as string,
          supportUserIds as string[],
        )
        .then((response) => {
          if (response.data.errors.length) {
            Vue.toasted.global.error(`${response.data.errors}`);
          } else {
            if (response.data.supportUsersAdded.length) {
              Vue.toasted.global.success(
                `Successfully added the following support users: ${response.data.supportUsersAdded}`,
              );
            }
            this.$emit('close');
          }
        });
    },
    isUserInProject(userId: number) {
      return this.userIds && this.userIds.some((id) => id === userId);
    },
  },
});
