
















































































import Vue, { PropType } from 'vue';
import { RawLocation } from 'vue-router';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { RouteNames } from '@/models/config/route-name';
import { ProjectSearchResult } from '@/models/projects/project-search-result';
import { ProjectAndEmailSearchRequest } from '@/models/search/accounts/project-and-email-search-request';
import { projectService } from '@/services/project.service';
import { ProjectMutationTypes } from '@/store/project-mutation-types';
import { EnvironmentConfig } from '@/utilities/environment-config';

export default Vue.extend({
  name: 'ProjectInformation',
  components: {
    LoadingSpinner,
  },
  props: {
    project: {
      type: Object as PropType<ProjectSearchResult>,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      localProject: null as ProjectSearchResult | null,
    };
  },
  created() {
    if (this.project !== null) {
      this.setLocalProject(this.project);
      this.isLoading = false;
      return;
    }

    const projectId = this.$route.params.projectId as string;
    this.getProject(projectId);
  },
  methods: {
    getProject(projectId: string) {
      const request = {
        searchValue: projectId,
      } as ProjectAndEmailSearchRequest;

      projectService.search(request).then((response) => {
        const project = response.data.data[0];
        this.setLocalProject(project);
        this.$store.commit(ProjectMutationTypes.ADD_PROJECT, project);
        this.isLoading = false;
      });
    },
    setLocalProject(project: ProjectSearchResult): void {
      this.localProject = JSON.parse(JSON.stringify(project));
    },
    getAccountRoute(accountId: string): RawLocation {
      return {
        name: RouteNames.ACCOUNT_DETAILS,
        params: {
          accountId,
        },
      };
    },
    externalProjectRoute(projectId: string): RawLocation {
      return `${EnvironmentConfig.config.gridfoxWebsiteUi}/apps/${projectId}`;
    },
  },
});
