export enum DisplayType {
  None = 0,
  Money = 100,
  Percentage = 200,
  SmallText = 300,
  MediumText = 400,
  LargeText = 500,
  Url = 600,
  Signature = 700,
}
