/* eslint-disable @typescript-eslint/naming-convention */
import { EntityRelationshipLine, LinePosition } from '@/models/projects/model/entity-relationship-line';
import { LineIntersection } from '@/models/projects/model/line-intersection';
import { ProjectModelEntity } from '@/models/projects/model/project-model-entity';
import { Position } from '@/models/shared/position';

export class DataModelHelper {
  static findLineIntersection(
    canvasOffset: { top: number; left: number },
    ctx: CanvasRenderingContext2D,
    entity: ProjectModelEntity,
    entityRelationship: ProjectModelEntity,
    traceline: LinePosition,
    entityRelationshipsLine: EntityRelationshipLine[],
  ): void {
    const intersections = [] as LineIntersection[];
    const intersectedEntityLines = [] as LinePosition[];
    const el1 = document.querySelector(`.entity[data-id="${entity.id}"] .bounding`);
    const el2 = document.querySelector(`.entity[data-id="${entityRelationship.id}"] .bounding`);
    const elements = [el1, el2] as HTMLElement[];

    if (el1 && el2) {
      elements.forEach((element: HTMLElement) => {
        const lines = [];
        const elementBound = element.getBoundingClientRect();
        const bound = {
          top: elementBound.top - canvasOffset.top,
          left: elementBound.left - canvasOffset.left,
          width: elementBound.width,
          height: elementBound.height,
        };

        const lineA = {
          from: { x: bound.left, y: bound.top },
          to: { x: (bound.left + bound.width), y: bound.top },
        } as LinePosition;
        lines.push(lineA);
        const lineB = {
          from: { x: (bound.left + bound.width), y: bound.top },
          to: { x: (bound.left + bound.width), y: (bound.top + bound.height) },
        } as LinePosition;
        lines.push(lineB);
        const lineC = {
          from: { x: bound.left, y: (bound.top + bound.height) },
          to: { x: (bound.left + bound.width), y: (bound.top + bound.height) },
        } as LinePosition;
        lines.push(lineC);
        const lineD = {
          from: { x: bound.left, y: bound.top },
          to: { x: bound.left, y: (bound.top + bound.height) },
        } as LinePosition;
        lines.push(lineD);

        lines.forEach((line: LinePosition) => {
          const intersection = DataModelHelper.checkLineIntersection(
            line.from.x,
            line.from.y,
            line.to.x,
            line.to.y,
            traceline.from.x,
            traceline.from.y,
            traceline.to.x,
            traceline.to.y,
          );
          if (intersection && intersection.onLine1 && intersection.onLine2) {
            intersections.push(intersection);
            intersectedEntityLines.push(line);
          }
        });
      });

      DataModelHelper.drawMidLine(
        ctx,
        intersections,
        entity,
        entityRelationship,
        entityRelationshipsLine,
        intersectedEntityLines,
      );
    }
  }

  private static drawMidLine(
    ctx: CanvasRenderingContext2D,
    intersections: LineIntersection[],
    entity: ProjectModelEntity,
    entityRelationship: ProjectModelEntity,
    entityRelationshipsLine: EntityRelationshipLine[],
    intersectedEntityLines: LinePosition[],
  ): void {
    const entityRelationshipLines = entityRelationshipsLine.filter((eR) => {
      const isEntity = eR.entity === entity;
      const isRelationship = eR.relatedEntity === entityRelationship;
      return isEntity && isRelationship;
    });
    entityRelationshipLines.sort((a, b) => {
      if (a.isManyToMany === true && b.isManyToMany === false) {
        return -1;
      }
      return 1;
    });
    entityRelationshipLines.forEach((entityRelationshipLine, index) => {
      if (entityRelationshipLine
          && intersections
          && intersections.length === 2
          && ctx) {
        const interEntityOne = intersections[0];
        const interEntityTwo = intersections[1];
        if (interEntityOne && interEntityOne.x && interEntityOne.y
            && interEntityTwo && interEntityTwo.x && interEntityTwo.y) {
          const line: LinePosition = {
            from: { x: interEntityOne.x, y: interEntityOne.y },
            to: { x: interEntityTwo.x, y: interEntityTwo.y },
          };

          if (index > 0) {
            DataModelHelper.offsetMultipleLines(line, index, intersectedEntityLines);
          }

          entityRelationshipLine.line = line;
          const limitBound = 10;
          ctx.beginPath();
          const lineLength = DataModelHelper.lineDistance(line);
          ctx.moveTo(line.from.x, line.from.y);
          if (lineLength < limitBound * 3) {
            const { x, y } = DataModelHelper.toBorder(line, limitBound);
            ctx.lineTo(x, y);
          } else {
            ctx.lineTo(line.to.x, line.to.y);
          }
          ctx.strokeStyle = '#346ace';
          ctx.closePath();
          ctx.stroke();
          if (lineLength > limitBound * 2) {
            DataModelHelper.drawArrowhead(ctx, line.to, line.from);

            if (entityRelationshipLine.isManyToMany) {
              DataModelHelper.drawArrowhead(ctx, line.from, line.to);
            }
          }
        }
      }
    });
  }

  private static isLineHorizontal(line: LinePosition): boolean {
    const xDifference = line.to.x - line.from.x;
    const yDifference = line.to.y - line.from.y;
    return Math.abs(xDifference) > Math.abs(yDifference);
  }

  private static offsetMultipleLines(
    line: LinePosition,
    index: number,
    intersectedEntityLines: LinePosition[],
  ): void {
    const isHorizontal = DataModelHelper.isLineHorizontal(line);
    if (isHorizontal) {
      line.from.y += (index * 20);
      line.to.y += (index * 20);
    } else {
      line.from.x += (index * 20);
      line.to.x += (index * 20);
    }

    const entityLineOne = intersectedEntityLines[0];
    const entityLineTwo = intersectedEntityLines[1];

    if (entityLineOne && entityLineTwo) {
      if (line.to.y > entityLineTwo.to.y) {
        const difference = line.to.y - entityLineTwo.to.y;
        line.to.y -= difference;
        line.to.x -= difference;
      }

      let { from } = entityLineOne;
      let { to } = entityLineOne;

      if (to.x < from.x) {
        from = entityLineOne.to;
        to = entityLineOne.from;
      }

      if ((line.from.x > from.x && line.from.x < to.x)
            && line.from.y > from.y) {
        const difference = line.from.y - from.y;
        line.from.x -= difference;
        line.from.y -= difference;
      }
    }
  }

  private static toBorder(line: LinePosition, limitBound: number): Position {
    const top = line.from.y - limitBound;
    const left = line.from.x - limitBound;
    const right = line.from.x + limitBound;
    const bottom = line.from.y + limitBound;
    const vx = line.to.x - line.from.x;
    const vy = line.to.y - line.from.y;
    const py = vy < 0 ? top : bottom;
    let dx = vx < 0 ? left : right;
    let dy = py;
    if (vx === 0) {
      dx = line.from.x;
    } else if (vy === 0) {
      dy = line.from.y;
    } else {
      dy = line.from.y + (vy / vx) * (dx - line.from.x);
      if (dy < top || dy > bottom) {
        dx = line.from.x + (vx / vy) * (py - line.from.y);
        dy = py;
      }
    }
    return { x: dx, y: dy };
  }

  private static lineDistance(line: LinePosition): number {
    let xs = 0;
    let ys = 0;
    xs = line.to.x - line.from.x;
    xs *= xs;
    ys = line.to.y - line.from.y;
    ys *= ys;
    return Math.sqrt(xs + ys);
  }

  private static checkLineIntersection(
    line1StartX: number,
    line1StartY: number,
    line1EndX: number,
    line1EndY: number,
    line2StartX: number,
    line2StartY: number,
    line2EndX: number,
    line2EndY: number,
  ): LineIntersection {
    // Logic taken from https://stackoverflow.com/a/47768601/4051181
    // if the lines intersect, the result contains the x and y of the intersection
    // (treating the lines as infinite) and booleans for whether line segment 1
    // or line segment 2 contain the point
    let a; let b;
    const result = {
      x: null,
      y: null,
      onLine1: false,
      onLine2: false,
    } as LineIntersection;
    const denominator = ((line2EndY - line2StartY) * (line1EndX - line1StartX))
         - ((line2EndX - line2StartX) * (line1EndY - line1StartY));
    if (denominator === 0) {
      return result;
    }
    a = line1StartY - line2StartY;
    b = line1StartX - line2StartX;
    const numerator1 = ((line2EndX - line2StartX) * a) - ((line2EndY - line2StartY) * b);
    const numerator2 = ((line1EndX - line1StartX) * a) - ((line1EndY - line1StartY) * b);
    a = numerator1 / denominator;
    b = numerator2 / denominator;

    // if we cast these lines infinitely in both directions, they intersect here:
    result.x = Math.round(line1StartX + (a * (line1EndX - line1StartX)));
    result.y = Math.round(line1StartY + (a * (line1EndY - line1StartY)));
    /*
        // it is worth noting that this should be the same as:
        x = line2StartX + (b * (line2EndX - line2StartX));
        y = line2StartX + (b * (line2EndY - line2StartY));
      */
    // if line1 is a segment and line2 is infinite, they intersect if:
    if (a > 0 && a < 1) {
      result.onLine1 = true;
    }
    // if line2 is a segment and line1 is infinite, they intersect if:
    if (b > 0 && b < 1) {
      result.onLine2 = true;
    }
    // if line1 and line2 are segments, they intersect if both of the above are true
    return result;
  }

  private static drawArrowhead(
    context: CanvasRenderingContext2D,
    from: Position,
    to: Position,
  ): void {
    const x_center = to.x;
    const y_center = to.y;
    const radius = 8;

    let angle;
    let x;
    let y;

    context.beginPath();
    angle = Math.atan2(to.y - from.y, to.x - from.x);
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    context.moveTo(x, y);

    angle += (1.0 / 3.0) * (2 * Math.PI);
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    context.lineTo(x, y);

    angle += (1.0 / 3.0) * (2 * Math.PI);
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    context.lineTo(x, y);
    context.lineWidth = 0;
    context.closePath();

    context.fillStyle = '#346ace';
    context.fill();
  }
}
