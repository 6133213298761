






































































































import Vue, { PropType } from 'vue';

import { DataModelHelper } from '@/helpers/model/data-model-helper';
import { Currency } from '@/models/projects/model/currency';
import { DisplayType } from '@/models/projects/model/display-type';
import { EntityRelationshipLine } from '@/models/projects/model/entity-relationship-line';
import { FieldType } from '@/models/projects/model/field-type';
import { ProjectModel } from '@/models/projects/model/project-model';
import { ProjectModelEntity } from '@/models/projects/model/project-model-entity';
import { ProjectModelField } from '@/models/projects/model/project-model-field';
import { Position } from '@/models/shared/position';

export default Vue.extend({
  name: 'ProjectModelExplorer',
  props: {
    projectModel: {
      type: Object as PropType<ProjectModel>,
      default: null,
    },
  },
  data() {
    return {
      keypressed: null as string | null,
      mousePosition: { x: 0, y: 0 } as Position,
      mousePositionCached: null as Position | null,
      draggedEntityId: null as string | null,
      draggedEntityPositionCached: null as Position | null,
      draggedCanvasPositionCached: null as Position | null,
      canvas: null as HTMLCanvasElement | null,
      canvasDrag: {
        state: false,
        mouseCache: { x: 0, y: 0 } as Position,
        entitiesPositions: [] as ProjectModelEntity[],
      },
      canvasOffset: {
        top: 0,
        left: 0,
      },
      cacheCanvasPosition: { x: 0, y: 0 } as Position,
      ctx: null as CanvasRenderingContext2D | null,
      entityRelationships: [] as EntityRelationshipLine[],
      showAdvanced: false,
      hoveredRelationshipId: null as string | null,
    };
  },
  computed: {
    gridPosition(): string {
      const { x, y } = this.cacheCanvasPosition;
      return `${x}px ${y}px`;
    },
  },
  mounted() {
    this.canvasSetup();
    window.addEventListener('mousemove', this.mousemove);
    window.addEventListener('mousedown', this.mousedown);
    window.addEventListener('mouseup', this.mouseup);
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('keyup', this.keyup);
  },
  destroyed() {
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mousedown', this.mousedown);
    window.removeEventListener('mouseup', this.mouseup);
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('keyup', this.keyup);
  },
  methods: {
    async toggleShowAdvanced(): Promise<void> {
      this.showAdvanced = !this.showAdvanced;

      await this.$nextTick();
      this.drawCanvas();
    },
    dragEntity(entityId: string): void {
      this.mousePositionCached = { ...this.mousePosition };
      this.draggedEntityId = entityId;
    },
    stopDraggingEntity(): void {
      this.mousePositionCached = null;
      this.draggedEntityPositionCached = null;
      this.draggedEntityId = null;
    },
    getEntityStyle(entity: ProjectModelEntity): {
      top: string;
      left: string;
      zIndex: number | null;
    } | undefined {
      if (entity && entity.position && entity.position.y && entity.position.x) {
        const zIndex = entity.id === this.draggedEntityId ? 1 : null;
        return {
          top: `${entity.position.y}px`,
          left: `${entity.position.x}px`,
          zIndex,
        };
      }

      return undefined;
    },
    getDisplayFields(entity: ProjectModelEntity): ProjectModelField[] {
      if (this.showAdvanced) {
        return entity.fields;
      }

      return entity.fields.filter((f) => !f.entityRelationshipId);
    },
    canvasSetup(): void {
      if (this.$refs.canvas) {
        this.canvas = this.$refs.canvas as HTMLCanvasElement;
        if (this.canvas) {
          this.ctx = this.canvas.getContext('2d');
          this.drawCanvas();
        }
      }
    },
    drawLinesForRelationships(): void {
      this.projectModel.entities.forEach((entity: ProjectModelEntity) => {
        entity.fields.forEach((field) => {
          if (!field.entityRelationshipId
            || (field.fieldType !== FieldType.Parent && field.fieldType !== FieldType.ManyToMany)) {
            return;
          }

          const relatedEntity = this.projectModel.entities.find(
            (e) => e.id === field.entityRelationshipId,
          );

          const container = document.querySelector('.data-designer');
          if (!container || !relatedEntity) {
            return;
          }

          const offsetY = (container.getBoundingClientRect() as DOMRect).top + 50;

          const traceline = {
            from: {
              x: entity.position.x + 130,
              y: entity.position.y + offsetY - this.canvasOffset.top,
            },
            to: {
              x: relatedEntity.position.x + 130,
              y: relatedEntity.position.y + offsetY - this.canvasOffset.top,
            },
          };

          const relationship = this.entityRelationships.find(
            (eR) => eR.entity === entity
              && eR.relatedEntity === relatedEntity
              && eR.entityFieldId === field.id,
          );

          if (relationship) {
            relationship.isManyToMany = field.fieldType === FieldType.ManyToMany;
          } else {
            this.entityRelationships.push({
              entity,
              relatedEntity,
              entityFieldId: field.id,
              line: null,
              isManyToMany: field.fieldType === FieldType.ManyToMany,
            });
          }

          if (this.ctx) {
            DataModelHelper.findLineIntersection(
              this.canvasOffset,
              this.ctx,
              entity,
              relatedEntity,
              traceline,
              this.entityRelationships,
            );
          }
        });
      });
    },
    drawCanvas(): void {
      const canvasContainer = this.$refs.canvasContainer as HTMLElement;

      if (this.canvas && canvasContainer) {
        const {
          top, left, width, height,
        } = canvasContainer.getBoundingClientRect();
        this.canvas.width = width;
        this.canvas.height = height;
        this.canvasOffset.top = top;
        this.canvasOffset.left = left;
      }

      this.drawLinesForRelationships();
    },
    dragCanvas(): void {
      const { mouseCache } = this.canvasDrag;
      if (mouseCache !== null) {
        this.projectModel.entities.forEach((entity) => {
          const entityCache = this.canvasDrag.entitiesPositions.find(
            (e) => e.id === entity.id,
          );
          if (entityCache) {
            const x = entityCache.position.x - (mouseCache.x - this.mousePosition.x);
            const y = entityCache.position.y - (mouseCache.y - this.mousePosition.y);
            entity.position = { x, y };
          }
        });
      }
    },
    keydown(e: KeyboardEvent): void {
      this.keypressed = e.key;
    },
    keyup(): void {
      this.keypressed = null;
    },
    mousemove(event: MouseEvent): void {
      this.mousePosition.x = event.clientX;
      this.mousePosition.y = event.clientY;
      if (this.draggedEntityId) {
        const entity = this.projectModel.entities.find((e) => e.id === this.draggedEntityId);
        if (entity) {
          if (!this.draggedEntityPositionCached) {
            this.draggedEntityPositionCached = { ...entity.position };
          }
          if (this.draggedEntityPositionCached && this.mousePositionCached) {
            entity.position.x = this.draggedEntityPositionCached.x
              - (this.mousePositionCached.x - this.mousePosition.x);
            entity.position.y = this.draggedEntityPositionCached.y
              - (this.mousePositionCached.y - this.mousePosition.y);
          }
        }
      } else if (this.canvasDrag.state) {
        if (this.mousePositionCached) {
          this.cacheCanvasPosition.x = -(this.mousePositionCached.x - this.mousePosition.x);
          this.cacheCanvasPosition.y = -(this.mousePositionCached.y - this.mousePosition.y);
          this.dragCanvas();
        }
      }

      this.drawCanvas();
    },
    mousedown(e: MouseEvent): void {
      const isCanvas = e.target === this.$refs.canvas;

      if (isCanvas) {
        this.mousePositionCached = { ...this.mousePosition };

        const entities: ProjectModelEntity[] = JSON.parse(
          JSON.stringify(this.projectModel.entities),
        );

        const entitiesPositions = entities.map((entity) => ({
          id: entity.id,
          position: entity.position,
        }));

        this.canvasDrag = {
          state: isCanvas,
          mouseCache: { ...this.mousePosition },
          entitiesPositions: entitiesPositions as ProjectModelEntity[],
        };
      }
    },
    mouseup(): void {
      this.stopDraggingEntity();
      if (this.canvasDrag.state) {
        this.canvasDrag.state = false;
        this.canvasDrag.mouseCache = { x: 0, y: 0 };
      }
    },
    getFieldIcon(field: ProjectModelField): string {
      const { fieldType, displayType, currency } = field;

      /* eslint-disable global-require */
      switch (fieldType) {
        case FieldType.Text:
          switch (displayType) {
            case DisplayType.None:
            case DisplayType.SmallText:
              return require('@/assets/field-icons/text.svg');
            case DisplayType.MediumText:
              return require('@/assets/field-icons/textarea.svg');
            case DisplayType.LargeText:
              return require('@/assets/field-icons/rich-text.svg');
            case DisplayType.Url:
              return require('@/assets/field-icons/url.svg');
            default:
              throw new Error(`Unexpected display type: ${displayType}`);
          }
        case FieldType.Number:
          return require('@/assets/field-icons/number.svg');
        case FieldType.Money:
          switch (currency) {
            case Currency.GBP:
              return require('@/assets/field-icons/money-gbp.svg');
            case Currency.USD:
              return require('@/assets/field-icons/money-usd.svg');
            case Currency.EUR:
              return require('@/assets/field-icons/money-eur.svg');
            default:
              return require('@/assets/field-icons/unknown.svg');
          }
        case FieldType.Percentage:
          return require('@/assets/field-icons/percentage.svg');
        case FieldType.Date:
          return require('@/assets/field-icons/date.svg');
        case FieldType.DateTime:
          return require('@/assets/field-icons/time.svg');
        case FieldType.Boolean:
          return require('@/assets/field-icons/checkbox.svg');
        case FieldType.List:
          return require('@/assets/field-icons/list.svg');
        case FieldType.Child:
          return require('@/assets/field-icons/link-child.svg');
        case FieldType.Parent:
          return require('@/assets/field-icons/link-parent.svg');
        case FieldType.ManyToMany:
          return require('@/assets/field-icons/link-many-to-many.svg');
        case FieldType.Counter:
          return require('@/assets/field-icons/auto-counter.svg');
        case FieldType.Image:
          return require('@/assets/field-icons/image.svg');
        case FieldType.Icon:
          return require('@/assets/field-icons/icon.svg');
        case FieldType.File:
          return require('@/assets/field-icons/file.svg');
        case FieldType.Computed:
          return require('@/assets/field-icons/formula.svg');
        case FieldType.User:
          return require('@/assets/field-icons/user.svg');
        case FieldType.MultiSelectList:
          return require('@/assets/field-icons/multi-select-list.svg');
        default:
          return require('@/assets/field-icons/unknown.svg');
      }
      /* eslint-enable global-require */
    },
  },
});
