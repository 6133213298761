















































import Vue from 'vue';

import ProjectInformation from '@/components/projects/project/ProjectInformation.vue';
import ProjectModelVue from '@/components/projects/project/ProjectModel.vue';
import ProjectModelJson from '@/components/projects/project/ProjectModelJson.vue';
import { ProjectModel } from '@/models/projects/model/project-model';
import { ProjectSearchResult } from '@/models/projects/project-search-result';
import { projectService } from '@/services/project.service';

export default Vue.extend({
  name: 'ProjectDetails',
  components: {
    ProjectInformation,
    ProjectModel: ProjectModelVue,
    ProjectModelJson,
  },
  data() {
    return {
      isLoading: true,
      active: 'model',
      projectModel: {} as ProjectModel,
    };
  },
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    project(): ProjectSearchResult {
      return this.$store.getters.getProject(this.projectId);
    },
  },
  async created() {
    await this.getDetails();
  },
  methods: {
    async getDetails() {
      this.isLoading = true;

      const response = await projectService.getModel(this.projectId);
      if (response) {
        this.projectModel = response.data;
      }

      this.isLoading = false;
    },
  },
});
