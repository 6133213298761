




































import Vue, { PropType } from 'vue';

import { SortRequest } from '@/models/search/sort-request';

export default Vue.extend({
  name: 'HeaderCell',
  props: {
    sortable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object as PropType<SortRequest>,
      required: true,
    },
    sortProperty: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    sort() {
      const descending = this.value.sortProperty === this.sortProperty
        ? !this.value.descending
        : false;

      this.$emit('input', {
        sortProperty: this.sortProperty,
        descending,
      } as SortRequest);

      this.$emit('sort-changed');
    },
  },
});
