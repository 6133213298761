

























































import Vue from 'vue';
import { RawLocation } from 'vue-router';
import { PropType } from 'vue/types/options';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { AccountDetails } from '@/models/accounts/account-details';
import { RouteNames } from '@/models/config/route-name';

export default Vue.extend({
  name: 'InvitedProjects',
  components: {
    LoadingSpinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    accountDetails: {
      type: Object as PropType<AccountDetails>,
      default: null,
    },
  },
  computed: {
    currentAccountId(): string | undefined {
      return this.$route.params.accountId;
    },
  },
  methods: {
    accountRoute(accountId: string) {
      return {
        name: RouteNames.ACCOUNT_DETAILS,
        params: {
          accountId,
        },
      } as RawLocation;
    },
    projectRoute(projectId: string) {
      return {
        name: RouteNames.PROJECT_DETAILS,
        params: {
          projectId,
        },
      } as RawLocation;
    },
  },
});
