export class EnumHelper {
  static getEnumAsIdNamePairs<TEnum>(
    enumObject: TEnum,
  ): { id: keyof TEnum; name: TEnum[keyof TEnum] }[] {
    const pairs = (Object.keys(enumObject) as Array<keyof TEnum>)
      .filter((key) => typeof enumObject[key] === 'string')
      .map((key) => ({
        id: key,
        name: enumObject[key],
      }));

    return pairs;
  }
}
