








import Vue from 'vue';

export default Vue.extend({
  name: 'LoadingSpinner',
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
});
