













































































































import { mixins } from 'vue-class-component';
import { RawLocation } from 'vue-router';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import HeaderCell from '@/components/shared/table/HeaderCell.vue';
import PagingControls from '@/components/shared/table/PagingControls.vue';
import SearchTable from '@/mixins/search-table';
import { RouteNames } from '@/models/config/route-name';
import { ProjectSearchResult } from '@/models/projects/project-search-result';
import { IPage } from '@/models/search/i-page';

export default mixins(SearchTable).extend({
  name: 'ProjectsTable',
  components: {
    HeaderCell,
    LoadingSpinner,
    PagingControls,
  },
  methods: {
    getProjectRoute(projectId: string): RawLocation {
      return {
        name: RouteNames.PROJECT_DETAILS,
        params: {
          projectId,
        },
      } as RawLocation;
    },
    openProject(projectId: string) {
      this.$router.push(this.getProjectRoute(projectId));
    },
    openProjectInNewWindow(projectId: string) {
      const route = this.$router.resolve(this.getProjectRoute(projectId));
      window.open(route.href, '_blank');
    },
  },
  computed: {
    projects(): IPage<ProjectSearchResult> {
      return this.$store.state.projectsStore.projects;
    },
    isLoading(): boolean {
      return this.$store.state.projectsStore.isProjectsLoading;
    },
  },
});
