
















































































import Vue from 'vue';
import { PropType } from 'vue/types/options';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { ChecklistHelper } from '@/helpers/checklist-helper';
import { ChecklistModuleProgress } from '@/models/checklists/checklist-module-progress';
import { ChecklistModuleType } from '@/models/checklists/checklist-module-types';
import { ChecklistProgress } from '@/models/checklists/checklist-progress';

export default Vue.extend({
  name: 'AccountChecklists',
  components: {
    LoadingSpinner,
  },
  filters: {
    stepStatus: (value: string) => (value ? 'Completed' : 'Incomplete'),
    moduleStatus: (progress: ChecklistModuleProgress) => {
      if (progress.dateCompleted) {
        return 'Completed';
      }

      const totalSteps = Object.keys(progress.steps).length;
      const completedSteps = Object.values(progress.steps).filter((s) => s)
        .length;

      if (completedSteps === 0) {
        return 'Not Started';
      }

      return `In Progress (${completedSteps}/${totalSteps})`;
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    checklistProgress: {
      type: Object as PropType<ChecklistProgress>,
      default: null,
    },
  },
  data() {
    return {
      moduleTypes: ChecklistModuleType,
      visibleModules: [] as number[],
    };
  },
  methods: {
    getSteps(moduleType: string) {
      return ChecklistHelper.getSteps(parseInt(moduleType, 10));
    },
    isModuleOpen(moduleType: number) {
      return this.visibleModules.includes(moduleType);
    },
    toggleModule(moduleType: number) {
      if (this.isModuleOpen(moduleType)) {
        this.visibleModules = this.visibleModules.filter((m) => m !== moduleType);
      } else {
        this.visibleModules.push(moduleType);
      }
    },
    getModuleStatusClass(progress: ChecklistModuleProgress) {
      if (progress.dateCompleted) {
        return 'checklist-status--completed';
      }

      const completedSteps = Object.values(progress.steps).filter((s) => s)
        .length;

      if (completedSteps === 0) {
        return 'checklist-status--incomplete';
      }

      return 'checklist-status--in-progress';
    },
  },
});
