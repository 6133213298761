


















































import Vue from 'vue';

import AllMetadata from '@/components/accounts/AllMetadata.vue';
import { authService } from '@/services/auth.service';

export default Vue.extend({
  components: {
    AllMetadata,
  },
  data() {
    return {
      darkMode: false,
    };
  },
  created(): void {
    this.loadTheme();
  },
  methods: {
    setTheme(theme: boolean): void {
      this.darkMode = theme !== undefined ? theme : !this.darkMode;
      if (this.darkMode) {
        document.body.classList.add('darkmode');
      } else {
        document.body.classList.remove('darkmode');
      }
      localStorage.setItem('darkmode', this.darkMode.toString());
    },
    loadTheme(): void {
      const themeValFromStorage = localStorage.getItem('darkmode');
      let theme = false;

      if (themeValFromStorage) {
        theme = JSON.parse(themeValFromStorage);
      }

      if (theme) {
        this.setTheme(theme);
      }
    },
    showMenuAsActive(url: string): boolean {
      return this.$route.fullPath.includes(url);
    },
    logout(): void {
      authService.logout();
    },
  },
});
