//* * Represents a collection of HTTP query string keys and values.
//* * Can be converted to a string implicitly or using the `toString` method.
export class Querystring extends Map<string, unknown> {
  private static isPrimitive(item: unknown): boolean {
    return (
      item instanceof Date
      || item instanceof Array
      || item instanceof Map
      || typeof item === 'string'
      || typeof item === 'boolean'
      || typeof item === 'number'
    );
  }

  constructor(source: unknown) {
    super();
    this.parseObject(source as Record<string, unknown>);
  }

  parseObject(source: Record<string, unknown>, prefix: string | null = null): void {
    Object.keys(source).forEach((key) => {
      const value = source[key];
      let queryKey = key;

      if (value == null) {
        return;
      }

      if (prefix) {
        queryKey = `${prefix}.${key}`;
      }

      if (Querystring.isPrimitive(value)) {
        this.set(key, value);
      } else {
        this.parseObject(value as Record<string, unknown>, queryKey);
      }
    });
  }

  public toString(): string {
    let delimiter = '?';
    let result = '';

    this.forEach((value, key) => {
      const queryKey = Querystring.encode(key);
      let queryValue = value;

      // Arrays should be encoded with duplicate keys, i.e.
      // ?key=value1&key=value2&key=value3
      if (queryValue instanceof Array) {
        queryValue.forEach((element) => {
          let queryElement = element;
          if (queryElement instanceof Date) {
            queryElement = queryElement.toISOString();
          }

          queryElement = Querystring.encode(element);
          result += delimiter;
          result += `${queryKey}=${queryElement}`;
          delimiter = '&';
        });
      } else {
        if (value instanceof Date) {
          queryValue = value.toISOString();
        }

        queryValue = Querystring.encode(queryValue as string);
        result += delimiter;
        result += queryKey;
        result += '=';
        result += value;
        delimiter = '&';
      }
    });

    return result;
  }

  private static encode = (value: string): string => encodeURIComponent(value);
}
