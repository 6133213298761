























import Vue from 'vue';

import AccountsFilter from '@/components/accounts/AccountsFilter.vue';
import AccountsTable from '@/components/accounts/AccountsTable.vue';
import { Category } from '@/models/categories/category';
import {
  AdminAccountDisplaySearchFilters,
  AdminAccountDisplaySearchRequest,
} from '@/models/search/accounts/admin-account-display-search-request';
import { PagingRequest } from '@/models/search/paging-request';
import { SortRequest } from '@/models/search/sort-request';
import { accountService } from '@/services/account.service';
import { categoryService } from '@/services/category.service';
import { parentCompanyService } from '@/services/parent-company.service';
import { AccountActionTypes } from '@/store/account-action-types';

export default Vue.extend({
  name: 'AccountsSearch',
  components: {
    AccountsFilter,
    AccountsTable,
  },
  data() {
    return {
      filters: null as AdminAccountDisplaySearchFilters | null,
      sortRequest: {
        sortProperty: 'lastAccess',
        descending: true,
      } as SortRequest,
      pagingRequest: {
        pageSize: 50,
        pageNumber: 0,
      },
      categories: new Array<Category>(),
      parentCompanies: new Array<string>(),
      triggerInitSearch: false,
    };
  },
  async created() {
    const [categoryResponse, parentCompanyResponse] = await Promise.all([
      categoryService.get(),
      parentCompanyService.get(),
    ]);

    this.categories = categoryResponse.data;
    this.parentCompanies = parentCompanyResponse.data;

    // Trigger search in Accounts Filter
    this.triggerInitSearch = true;
  },
  methods: {
    search() {
      const request = {
        ...this.filters,
        ...this.sortRequest,
        ...this.pagingRequest,
      } as AdminAccountDisplaySearchRequest;

      this.$store.dispatch(AccountActionTypes.FETCH_ACCOUNTS, request);
    },
    exportResults() {
      const request = {
        ...this.filters,
        ...this.sortRequest,
        ...this.pagingRequest,
      } as AdminAccountDisplaySearchRequest;

      accountService.export(request).then((response) => {
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'Gridfox Accounts.xlsx');

        document.body.appendChild(link);

        link.click();
        link.remove();

        URL.revokeObjectURL(url);
      });
    },
    filtersChanged(filters: AdminAccountDisplaySearchRequest) {
      this.filters = filters;
      this.resetPageNumber();
      this.search();
    },
    changedSort(request: SortRequest) {
      this.sortRequest = request;
      this.resetPageNumber();
      this.search();
    },
    changedPaging(request: PagingRequest) {
      this.pagingRequest = request;
      this.search();
    },
    resetPageNumber() {
      this.pagingRequest.pageNumber = 0;
    },
  },
});
