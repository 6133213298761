import Axios, { AxiosResponse } from 'axios';

import { Category } from '@/models/categories/category';

export class CategoryService {
  private readonly resourceName = '/categories';

  get(): Promise<AxiosResponse<Category[]>> {
    return Axios.get<Category[]>(this.resourceName);
  }
}

export const categoryService = new CategoryService();
