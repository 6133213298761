import Vue, { PropType } from 'vue';
import { RawLocation } from 'vue-router';

import { StorageHelper } from '@/helpers/storage-helper';
import { RouteNames } from '@/models/config/route-name';
import { PagingRequest } from '@/models/search/paging-request';
import { SortRequest } from '@/models/search/sort-request';

export default Vue.extend({
  props: {
    sortRequest: {
      type: Object as PropType<SortRequest>,
      required: true,
    },
    pagingRequest: {
      type: Object as PropType<PagingRequest>,
      required: true,
    },
  },
  data() {
    return {
      localSortRequest: this.sortRequest as SortRequest | null,
    };
  },
  watch: {
    localSortRequest() {
      this.sortChanged();
    },
  },
  methods: {
    sortChanged() {
      this.$emit('sort-changed', this.localSortRequest);
    },
    pagingChanged(request: PagingRequest) {
      this.$emit('paging-changed', request);
    },
    storageString(value: number): string {
      return StorageHelper.toMbString(value);
    },
    getAccountRoute(accountId: string): RawLocation {
      return {
        name: RouteNames.ACCOUNT_DETAILS,
        params: {
          accountId,
        },
      } as RawLocation;
    },
    viewAccount(accountId: string | null) {
      if (accountId) {
        this.$router.push(this.getAccountRoute(accountId));
      }
    },
  },
});
