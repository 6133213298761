


































import Vue from 'vue';

import { emailService } from '@/services/email.service';

export default Vue.extend({
  name: 'ConfirmResendEmailModal',
  props: {
    emailId: {
      type: String,
      required: true,
    },
    emailTos: {
      type: String,
      required: true,
    },
  },
  methods: {
    async resend() {
      await emailService
        .resend(this.$props.emailId)
        .then((response) => {
          if (response.status === 204) {
            Vue.toasted.global.success(
              `Successfully resent email to ${this.$props.emailTos}`,
            );
            this.$emit('fetchEmails');
            this.$emit('close');
          }
        });
    },
  },
});
