








































































































































































import { mixins } from 'vue-class-component';

import ConfirmResendEmailModal from '@/components/emails/ConfirmResendEmailModal.vue';
import EmailContentModal from '@/components/emails/EmailContentModal.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import HeaderCell from '@/components/shared/table/HeaderCell.vue';
import PagingControls from '@/components/shared/table/PagingControls.vue';
import SearchTable from '@/mixins/search-table';
import { EmailSearchResult } from '@/models/emails/email-search-result';
import { IPage } from '@/models/search/i-page';

export default mixins(SearchTable).extend({
  name: 'EmailsTable',
  components: {
    HeaderCell,
    LoadingSpinner,
    PagingControls,
    ConfirmResendEmailModal,
    EmailContentModal,
  },
  data() {
    return {
      resendModalVisible: false,
      contentModalVisible: false,
      selectedEmailId: '',
      selectedEmailTos: '',
      selectedEmailContent: '',
    };
  },
  methods: {
    openResendModal(emailId: string, emailTos: string) {
      this.selectedEmailId = emailId;
      this.selectedEmailTos = emailTos;
      this.resendModalVisible = true;
    },
    openContentModal(emailContent: string) {
      this.selectedEmailContent = emailContent;
      this.contentModalVisible = true;
    },
    refetch() {
      this.$emit('refetch-emails');
    },
  },
  computed: {
    emails(): IPage<EmailSearchResult> {
      return this.$store.state.emailsStore.emails;
    },
    isLoading(): boolean {
      return this.$store.state.emailsStore.isEmailsLoading;
    },
  },
});
