




import Vue from 'vue';

import { RouteNames } from '@/models/config/route-name';

export default Vue.extend({
  name: RouteNames.EMAILS,
});
