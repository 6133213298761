import { AxiosResponse } from 'axios';
import { Module } from 'vuex';

import { EmailSearchResult } from '@/models/emails/email-search-result';
import { ProjectAndEmailSearchRequest } from '@/models/search/accounts/project-and-email-search-request';
import { IPage } from '@/models/search/i-page';
import { emailService } from '@/services/email.service';
import { EmailActionTypes } from '@/store/email-action-types';
import { EmailMutationTypes } from '@/store/email-mutation-types';

type EmailStoreState = {
  emails: IPage<EmailSearchResult> | null;
  isEmailsLoading: boolean;
};

const emailsStore: Module<EmailStoreState, unknown> = {
  state: {
    emails: null,
    isEmailsLoading: true,
  },
  mutations: {
    [EmailMutationTypes.FETCH_EMAILS](
      state,
      emails: IPage<EmailSearchResult>,
    ) {
      state.emails = emails;
      state.isEmailsLoading = false;
    },
    [EmailMutationTypes.EMAILS_LOADING](state, loading: boolean) {
      state.isEmailsLoading = loading;
    },
  },
  actions: {
    [EmailActionTypes.FETCH_EMAILS](
      context,
      request: ProjectAndEmailSearchRequest,
    ): Promise<AxiosResponse<IPage<EmailSearchResult>>> {
      return new Promise((resolve, reject) => {
        context.commit(EmailMutationTypes.EMAILS_LOADING, true);
        emailService.search(request).then(
          (response) => {
            context.commit(EmailMutationTypes.FETCH_EMAILS, response.data);
            resolve(response);
          },
          (error) => {
            context.commit(EmailMutationTypes.EMAILS_LOADING, false);
            reject(error);
          },
        );
      });
    },
  },
};
export default emailsStore;
