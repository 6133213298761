import Axios, { AxiosResponse } from 'axios';

import { Querystring } from '@/helpers/http/query-string';
import { AdminAccountDisplay } from '@/models/accounts/account-admin-display';
import { AccountDetails } from '@/models/accounts/account-details';
import { AdminMetadata } from '@/models/accounts/admin-metadata';
import { UserDetail } from '@/models/accounts/user-detail';
import { AdminAccountDisplaySearchRequest } from '@/models/search/accounts/admin-account-display-search-request';
import { IPage } from '@/models/search/i-page';

export class AccountService {
  private readonly resourceName = '/accounts';

  search(
    request: AdminAccountDisplaySearchRequest,
  ): Promise<AxiosResponse<IPage<AdminAccountDisplay>>> {
    const url = `${this.resourceName}/search`;

    const queryString = new Querystring(request);

    const fullUrl = `${url}${queryString}`;

    return Axios.get<IPage<AdminAccountDisplay>>(fullUrl);
  }

  export(
    request: AdminAccountDisplaySearchRequest,
  ): Promise<AxiosResponse<Blob>> {
    const url = `${this.resourceName}/export`;

    const queryString = new Querystring(request);

    const fullUrl = `${url}${queryString}`;

    return Axios.request({
      url: fullUrl,
      responseType: 'blob',
      method: 'GET',
    });
  }

  toggleAccount(accountId: string): Promise<AxiosResponse> {
    return Axios.put(`${this.resourceName}/${accountId}/toggle`);
  }

  getAllMetadata(): Promise<AxiosResponse<AdminMetadata>> {
    return Axios.get(`${this.resourceName}/metadata`);
  }

  getAccountDetails(accountId: string): Promise<AxiosResponse<AccountDetails>> {
    return Axios.get(`${this.resourceName}/${accountId}`);
  }

  toggleReviewed(accountId: string): Promise<AxiosResponse> {
    return Axios.put(`${this.resourceName}/${accountId}/reviewed`);
  }

  setCategory(accountId: string, categoryId: string): Promise<AxiosResponse> {
    return Axios.put(`${this.resourceName}/${accountId}/category`, {
      categoryId,
    });
  }

  setReadOnlyUserLimit(accountId: string, readOnlyUserLimit: number): Promise<AxiosResponse> {
    return Axios.put(`${this.resourceName}/${accountId}/read-only-limit`, {
      readOnlyUserLimit,
    });
  }

  createProject(accountId: string, projectName: string): Promise<AxiosResponse> {
    return Axios.post(`${this.resourceName}/${accountId}/project`, {
      projectName,
    });
  }

  setParentCompany(accountId: string, name: string): Promise<AxiosResponse> {
    return Axios.put(
      `${this.resourceName}/${accountId}/parent-company`,
      {
        name,
      },
    );
  }

  setTrialExpiry(accountId: string, trialExpiry: Date): Promise<AxiosResponse> {
    return Axios.put(
      `${this.resourceName}/${accountId}/trial-expiry`,
      {
        trialExpiry,
      },
    );
  }

  setToPayByInvoice(accountId: string): Promise<AxiosResponse> {
    return Axios.put(
      `${this.resourceName}/${accountId}/pay-by-invoice`,
    );
  }

  setCustomHost(accountId: string, customHost: string): Promise<AxiosResponse> {
    return Axios.put(
      `${this.resourceName}/${accountId}/custom-host`,
      {
        customHost,
      },
    );
  }

  getSupportUsers(): Promise<AxiosResponse<UserDetail[]>> {
    return Axios.get(`${this.resourceName}/support-users`);
  }
}

export const accountService = new AccountService();
