export class StorageHelper {
  /**
   * Convert a storage value in MB to larger storage suffixes.
   * @param value The storage value in MB.
   */
  static toMbString(value: number): string {
    if (value > 1024) {
      return `${(value / 1024).toFixed(2)}GB`;
    }
    return `${value.toFixed(2)}MB`;
  }
}
