

























import Vue, { PropType } from 'vue';
import JsonViewer from 'vue-json-viewer';

import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { ProjectModel } from '@/models/projects/model/project-model';

export default Vue.extend({
  name: 'ProjectModelJson',
  components: {
    LoadingSpinner,
    JsonViewer,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    projectModel: {
      type: Object as PropType<ProjectModel>,
      default: null,
    },
  },
});
