import { ChecklistModuleType } from '@/models/checklists/checklist-module-types';
import { ChecklistStepCompleteProfile } from '@/models/checklists/checklist-steps/checklist-step-complete-profile';
import { ChecklistStepCreateABoard } from '@/models/checklists/checklist-steps/checklist-step-create-a-board';
import { ChecklistStepCreateATable } from '@/models/checklists/checklist-steps/checklist-step-create-a-table';
import { ChecklistStepInviteAUser } from '@/models/checklists/checklist-steps/checklist-step-invite-a-user';

export class ChecklistHelper {
  public static getSteps(moduleType: number): Record<number, string> {
    switch (moduleType) {
      case ChecklistModuleType.CreateATable:
        return ChecklistStepCreateATable;
      case ChecklistModuleType.CreateABoard:
        return ChecklistStepCreateABoard;
      case ChecklistModuleType.InviteAUser:
        return ChecklistStepInviteAUser;
      case ChecklistModuleType.CompleteProfile:
        return ChecklistStepCompleteProfile;
      default:
        throw new Error('Unknown module type');
    }
  }
}
