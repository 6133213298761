








































import Vue, { PropType } from 'vue';

import { PagingRequest } from '@/models/search/paging-request';

export default Vue.extend({
  name: 'PagingControls',
  props: {
    totalRecords: {
      type: Number,
      default: 0,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    pagingRequest: {
      type: Object as PropType<PagingRequest>,
      required: true,
    },
  },
  data() {
    return {
      localPagingRequest: this.pagingRequest as PagingRequest,
      pageSizes: [10, 25, 50, 100],
      showDropdown: false,
    };
  },
  computed: {
    getFirstDisplayedRecordNumber(): number {
      return (
        this.localPagingRequest.pageNumber * this.localPagingRequest.pageSize
        + 1
      );
    },
    getLastDisplayedRecordNumber(): number {
      const maxRecordNumber = this.localPagingRequest.pageNumber * this.localPagingRequest.pageSize
        + this.localPagingRequest.pageSize;
      return this.totalRecords < maxRecordNumber
        ? this.totalRecords
        : maxRecordNumber;
    },
    isFirstPage(): boolean {
      return this.localPagingRequest.pageNumber === 0;
    },
    isLastPage(): boolean {
      const lastPage = Math.ceil(this.totalRecords / this.localPagingRequest.pageSize) - 1;
      return this.localPagingRequest.pageNumber === lastPage;
    },
  },
  methods: {
    nextPage() {
      this.localPagingRequest.pageNumber += 1;
      this.pagingChanged();
    },
    previousPage() {
      this.localPagingRequest.pageNumber -= 1;
      this.pagingChanged();
    },
    changedSize(size: number) {
      this.localPagingRequest.pageSize = size;
      this.pagingChanged();
    },
    pagingChanged() {
      this.$emit('paging-changed', this.localPagingRequest);
    },
  },
});
