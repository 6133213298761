import Axios, { AxiosResponse } from 'axios';

import { Querystring } from '@/helpers/http/query-string';
import { AddSupportUserResult } from '@/models/projects/add-support-user-result';
import { ProjectModel } from '@/models/projects/model/project-model';
import { ProjectSearchResult } from '@/models/projects/project-search-result';
import { ProjectAndEmailSearchRequest } from '@/models/search/accounts/project-and-email-search-request';
import { IPage } from '@/models/search/i-page';

export class ProjectService {
  private readonly resourceName = '/projects';

  search(
    request: ProjectAndEmailSearchRequest,
  ): Promise<AxiosResponse<IPage<ProjectSearchResult>>> {
    const url = `${this.resourceName}/search`;

    const queryString = new Querystring(request);

    const fullUrl = `${url}${queryString}`;
    return Axios.get<IPage<ProjectSearchResult>>(fullUrl);
  }

  getProjectUserIds(projectId: string): Promise<AxiosResponse<number[]>> {
    return Axios.get(
      `${this.resourceName}/${projectId}/project-user-ids`,
    );
  }

  addSupportUser(
    projectId: string,
    userIds: string[],
  ): Promise<AxiosResponse<AddSupportUserResult>> {
    return Axios.post<AddSupportUserResult>(
      `${this.resourceName}/support`,
      {
        projectId,
        userIds,
      },
    );
  }

  edit(projectId: string, managed: boolean): Promise<AxiosResponse> {
    return Axios.put(`${this.resourceName}/${projectId}`, {
      managed,
    });
  }

  getModel(projectId: string): Promise<AxiosResponse<ProjectModel>> {
    return Axios.get(`${this.resourceName}/${projectId}/model`);
  }
}

export const projectService = new ProjectService();
