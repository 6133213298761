





















































import Vue, { PropType } from 'vue';

import { AccountProject } from '@/models/accounts/account-project';
import { managedAccountService } from '@/services/managed-account.service';

export default Vue.extend({
  name: 'CreateManagedAccountModal',
  props: {
    project: {
      type: Object as PropType<AccountProject>,
      default: () => {},
    },
    accountId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      accountOwnerEmail: null as string | null,

      accountName: null as string | null,
      emailError: false,
      accountNameError: false,
      disableCreateButton: false,
    };
  },
  methods: {
    async save() {
      if (this.disableCreateButton) {
        return;
      }
      if (this.accountOwnerEmail && this.accountName) {
        this.disableCreateButton = true;
        this.emailError = false;
        this.accountNameError = false;
        await managedAccountService.createManaged(
          this.accountOwnerEmail,
          this.accountName,
        );

        Vue.toasted.global.success(
          `Successfully created a managed account for the email ${this.accountOwnerEmail}`,
        );
        this.disableCreateButton = false;
        this.$emit('close');
      }
      this.disableCreateButton = false;
      this.emailError = this.accountOwnerEmail === null;
      this.accountNameError = this.accountName === null;
    },
  },
});
